
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'Shortcut',

    props: {
      keyLabel: {
        type: String,
        required: true,
      },
    },
  });
