
  import {
    computed, ref, defineComponent, onUnmounted,
  } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useRoute, useRouter } from 'vue-router';
  import { useStore } from 'vuex';

  import Button from '@/components/Button.vue';
  import Keyboard from '@/components/keyboard/Keyboard.vue';
  import KeyInline from '@/components/key-shortcut/KeyInline.vue';
  import Label from '@/components/html-elements/Label.vue';

  import {
    Systems, System, Formats, Format, Languages, Language, LayoutProps,
  } from '@/config/keyboards/dataTypes';
  import {
    KeyboardLayout,
  } from '@/config/keyboards/keyboardLayout';
  import { layouts as KeyboardLayouts } from '@/config/keyboards/layoutsAll';

  export default defineComponent({
    name: 'LayoutSetup',

    components: {
      Button,
      Keyboard,
      KeyInline,
      Label,
    },

    setup() {
      const { t } = useI18n({ useScope: 'global' });
      const route = useRoute();
      const router = useRouter();
      const store = useStore();

      const activeStep = ref(1);
      const system = ref(Systems);
      const formats = ref(Formats);
      const languages = ref(Object.values(Languages));

      const activeSettings = ref(['', '', '', '']);

      const layoutNames = computed((): {language: Language, names: string[]} => {
        if (activeSettings.value[2] === Languages.RU) {
          if (activeSettings.value[0] === Systems.WIN) {
            return {
              language: Languages.RU,
              names: [
                KeyboardLayouts[Languages.RU].names.Qwerty,
                KeyboardLayouts[Languages.RU].names.Typewriter,
              ],
            };
          }
          return {
            language: Languages.RU,
            names: [
              KeyboardLayouts[Languages.RU].names.RussianApple,
              KeyboardLayouts[Languages.RU].names.RussianApplePC,
            ],
          };
        }

        return {
          language: Languages.EN,
          names: [
            KeyboardLayouts[Languages.EN].names.Qwerty,
            KeyboardLayouts[Languages.EN].names.Dvorak,
            KeyboardLayouts[Languages.EN].names.Colemak,
          ],
        };
      });

      const keyToPress = computed((): 'Ё' | '.' | 'K' => {
        if (activeSettings.value[2] === Languages.RU) {
          if (activeSettings.value[1] === Formats.ISO) {
            return 'Ё';
          }
          if (activeSettings.value[1] === Formats.ANSI) {
            return '.';
          }
        }
        return 'K';
      });

      const selectedLayoutProps = computed((): LayoutProps | null => {
        for (let i = 0; i < activeSettings.value.length; i += 1) {
          if (!activeSettings.value[i]) {
            return null;
          }
        }

        const props: LayoutProps = {
          system: activeSettings.value[0] as System,
          format: activeSettings.value[1] as Format,
          language: activeSettings.value[2] as Language,
          name: activeSettings.value[3] as string,
        };

        return props;
      });

      const definedLayout = computed((): KeyboardLayout | null => {
        const props = selectedLayoutProps.value;
        if (!props) { return null; }

        const layoutsToCheck = KeyboardLayouts[props.language].layouts;
        for (let layoutIx = 0; layoutIx < layoutsToCheck.length; layoutIx += 1) {
          const layout = layoutsToCheck[layoutIx];
          if (layout.isEqual(props)) {
            return layout;
          }
        }

        return null;
      });

      // Define layout by key (if 1-3 settings already selected)

      const defineLayoutByKey = (e: KeyboardEvent): void => {
        for (let i = 0; i < activeSettings.value.length - 1; i += 1) {
          if (activeSettings.value[i] === '') {
            return;
          }
        }

        const activeSystem = activeSettings.value[0] as System;
        const activeFormat = activeSettings.value[1] as Format;
        const activeLanguage = activeSettings.value[2] as Language;

        const definedName = KeyboardLayouts[activeLanguage].defineByKeyPress(e, activeSystem, activeFormat);

        if (definedName) {
          // eslint-disable-next-line no-use-before-define
          selectOption(3, definedName.props.name);
        }
      };

      // Select btn handler

      const setLayout = (): void => {
        const props = selectedLayoutProps.value;
        if (props) { store.commit('layout/set', { settings: props }); }
      };

      const selectOption = (stepIndex: number, value: string): void => {
        if (activeSettings.value[stepIndex] === value) return;

        activeSettings.value[stepIndex] = value;
        activeStep.value = Math.max(activeStep.value, stepIndex + 2);

        if (activeStep.value === 4) {
          document.addEventListener('keydown', defineLayoutByKey);
        }

        if (activeStep.value > 4) {
          document.removeEventListener('keydown', defineLayoutByKey);
          setLayout();
        }
      };

      const rerunSetup = (): void => {
        activeSettings.value = ['', '', '', ''];
        activeStep.value = 1;
      };

      onUnmounted((): void => {
        document.removeEventListener('keydown', defineLayoutByKey);
      });

      return {
        t,
        route,
        router,
        activeStep,
        activeSettings,
        selectOption,
        system,
        formats,
        languages,
        layoutNames,
        keyToPress,
        definedLayout,
        rerunSetup,
      };
    },
  });
