import { render } from "./LayoutSetup.vue?vue&type=template&id=0174f090&scoped=true"
import script from "./LayoutSetup.vue?vue&type=script&lang=ts"
export * from "./LayoutSetup.vue?vue&type=script&lang=ts"

import "./LayoutSetup.vue?vue&type=style&index=0&id=0174f090&lang=sass"
import "./LayoutSetup.vue?vue&type=style&index=1&id=0174f090&lang=scss"
import "./LayoutSetup.vue?vue&type=style&index=2&id=0174f090&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-0174f090"

export default script