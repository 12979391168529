<template>
  <div class="key-inline">
    {{ keyLabel }}
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'Shortcut',

    props: {
      keyLabel: {
        type: String,
        required: true,
      },
    },
  });
</script>

<style lang="scss" scoped>
.key-inline {
  display: inline-block;
  line-height: 1;
  font-size: .75em;
  font-weight: 500;
  border-radius: .25em;
  border: 1px solid rgb(var(--color-secondary-border));
  box-shadow: inset 0px -1px rgb(var(--color-secondary-border));
  padding: .4em .5em .325em;
  min-width: 1.75em;
  text-align: center;
  vertical-align: middle;
}
</style>
