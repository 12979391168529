<template>
  <section
    class="layout-setup"
    :class="{'success': definedLayout}"
  >
    <transition
      name="soft-switch"
      mode="out-in"
    >
      <div
        v-if="!definedLayout"
        class="setup-wrapper"
      >
        <h1 class="title main-title">
          {{ t(`pages.${route.name}.title.progress`) }}
        </h1>
        <div class="settings-wrapper">
          <div
            v-for="(step, stepIndex) in 4"
            :key="step"
            class="option-block"
            :class="{'selected': step < activeStep, 'active': step === activeStep, 'disabled': step > activeStep}"
          >
            <div class="step-indicator">
              {{ step }}
            </div>

            <div class="info">
              <h2 class="title">
                {{ t(`pages.${route.name}.steps[${stepIndex}].title`) }}
              </h2>
              <p
                v-if="step !== 4"
                class="description"
              >
                {{ t(`pages.${route.name}.steps[${stepIndex}].description`) }}
              </p>
              <p
                v-else
                class="description"
              >
                {{ t(`pages.${route.name}.steps[${stepIndex}].description[0]`) }}
                <KeyInline :key-label="keyToPress" />
                {{ t(`pages.${route.name}.steps[${stepIndex}].description[1]`) }}:
              </p>
            </div>

            <!-- System -->
            <div
              v-if="step === 1"
              class="actions"
            >
              <Button
                v-for="item in system"
                :key="item"
                type="select"

                :state="step < activeStep ?
                  (activeSettings[stepIndex] === item ? 'selected' : 'on-selected-block'): ''"
                class="select-option"
                @click="selectOption(stepIndex, item)"
              >
                {{ t(`systems.${item}`) }}
              </Button>
            </div>

            <!-- Format -->
            <div
              v-if="step === 2"
              class="actions formats-wrapper"
            >
              <Button
                type="select"

                :state="step < activeStep ?
                  (activeSettings[stepIndex] === formats.ISO ? 'selected' : 'on-selected-block'): ''"
                @click="selectOption(stepIndex, formats.ISO)"
              >
                <div class="format-option">
                  <svg viewBox="0 0 130 142" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="enter-border" fill-rule="evenodd" clip-rule="evenodd" d="M61 7C61 3.68629 63.6863 1 67 1H123C126.314 1 129 3.68629 129 7V135C129 138.314 126.314 141 123 141H86.9052C83.5915 141 80.9052 138.314 80.9052 135V71.2105C80.9052 67.8968 78.2189 65.2105 74.9052 65.2105H67C63.6863 65.2105 61 62.5242 61 59.2105V7Z" stroke-width="2" />
                    <path class="enter-icon" fill-rule="evenodd" clip-rule="evenodd" d="M109.685 33C108.959 33 108.37 33.583 108.37 34.3023V44.0693C108.37 45.7475 106.996 47.1079 105.301 47.1079H91.4899L97.614 41.0434C98.1276 40.5349 98.1276 39.7103 97.614 39.2017C97.1004 38.6932 96.2678 38.6932 95.7542 39.2017L87.3852 47.4893C86.8716 47.9979 86.8716 48.8224 87.3852 49.331L95.7542 57.6186C96.2678 58.1271 97.1004 58.1271 97.614 57.6186C98.1276 57.11 98.1276 56.2855 97.614 55.7769L91.4899 49.7124H105.301C108.449 49.7124 111 47.1859 111 44.0693V34.3023C111 33.583 110.411 33 109.685 33Z" />
                    <path class="secondary" d="M1 7C1 3.68629 3.68629 1 7 1H43C46.3137 1 49 3.68629 49 7V61C49 64.3137 46.3137 67 43 67H7C3.68629 67 1 64.3137 1 61V7Z" stroke-width="2" />
                    <path class="secondary" d="M1 81C1 77.6863 3.68629 75 7 75H63C66.3137 75 69 77.6863 69 81V135C69 138.314 66.3137 141 63 141H7C3.68629 141 1 138.314 1 135V81Z" stroke-width="2" />
                  </svg>
                  <div class="caption">
                    {{ t(`layoutFormats.${formats.ISO}`) }}
                  </div>
                </div>
              </Button>
              <Button
                type="select"

                :state="step < activeStep ?
                  (activeSettings[stepIndex] === formats.ANSI ? 'selected' : 'on-selected-block'): ''"
                @click="selectOption(stepIndex, formats.ANSI)"
              >
                <div class="format-option">
                  <svg viewBox="0 0 130 142" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="enter-border" d="M1 81C1 77.6863 3.68629 75 7 75H123C126.314 75 129 77.6863 129 81V135C129 138.314 126.314 141 123 141H7C3.68629 141 1 138.314 1 135V81Z" stroke-width="2" />
                    <path class="enter-icon" fill-rule="evenodd" clip-rule="evenodd" d="M109.685 99C108.959 99 108.37 99.583 108.37 100.302V110.069C108.37 111.747 106.996 113.108 105.301 113.108H91.4899L97.614 107.043C98.1276 106.535 98.1276 105.71 97.614 105.202C97.1004 104.693 96.2678 104.693 95.7542 105.202L87.3852 113.489C86.8716 113.998 86.8716 114.822 87.3852 115.331L95.7542 123.619C96.2678 124.127 97.1004 124.127 97.614 123.619C98.1276 123.11 98.1276 122.285 97.614 121.777L91.4899 115.712H105.301C108.449 115.712 111 113.186 111 110.069V100.302C111 99.583 110.411 99 109.685 99Z" />
                    <path class="secondary" d="M1 7C1 3.68629 3.68629 1 7 1H43C46.3137 1 49 3.68629 49 7V61C49 64.3137 46.3137 67 43 67H7C3.68629 67 1 64.3137 1 61V7Z" stroke-width="2" />
                    <path class="secondary" d="M61 7C61 3.68629 63.6863 1 67 1H123C126.314 1 129 3.68629 129 7V61C129 64.3137 126.314 67 123 67H67C63.6863 67 61 64.3137 61 61V7Z" stroke-width="2" />
                  </svg>
                  <div class="caption">
                    {{ t(`layoutFormats.${formats.ANSI}`) }}
                  </div>
                </div>
              </Button>
            </div>

            <!-- Language -->
            <div
              v-if="step === 3"
              class="actions"
            >
              <Button
                v-for="lang in languages"
                :key="lang"
                type="select"

                :state="step < activeStep ? (activeSettings[stepIndex] === lang ? 'selected' : 'on-selected-block'): ''"
                class="select-option"
                :icon-path="`language-icons/${lang}.png`"
                @click="selectOption(stepIndex, lang)"
              >
                {{ t(`languages.${lang}`) }}
              </Button>
            </div>

            <!-- Layouts -->
            <div
              v-if="step === 4"
              class="actions"
            >
              <Button
                v-for="layout in layoutNames.names"
                :key="layout"
                type="select"

                :state="step < activeStep ?
                  (activeSettings[stepIndex] === layout ? 'selected' : 'on-selected-block'): ''"
                class="select-option"
                @click="selectOption(stepIndex, layout)"
              >
                {{ t(`keyboardLayouts.${layoutNames.language}.${layout}`) }}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div
        v-else
        class="layout-preview"
      >
        <h1 class="title main-title">
          {{ t(`pages.${route.name}.title.done`) }}
        </h1>
        <p class="subtitle">
          <span>{{ t(`keyboardLayouts.${definedLayout.props.language}.${definedLayout.props.name}`) }}</span>
          <Label>{{ t(`layoutFormats.${definedLayout.props.format}`) }}</Label>
        </p>
        <div class="keyboard-wrapper">
          <Keyboard
            :layout-props="definedLayout.props"
            :handle-typing="true"
          />
        </div>
        <div class="final-actions">
          <Button
            type="primary"
            @click="router.push({ name: 'profile'})"
          >
            {{ t(`pages.${route.name}.actionButton`) }}
          </Button>

          <p class="additional-actions">
            {{ t(`pages.${route.name}.isWrong`) }}
            <a
              href="#"
              @click.prevent="rerunSetup"
            >{{ t(`pages.${route.name}.restartAction`) }}</a>
          </p>
        </div>
      </div>
    </transition>
  </section>
</template>

<script lang="ts">
  import {
    computed, ref, defineComponent, onUnmounted,
  } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useRoute, useRouter } from 'vue-router';
  import { useStore } from 'vuex';

  import Button from '@/components/Button.vue';
  import Keyboard from '@/components/keyboard/Keyboard.vue';
  import KeyInline from '@/components/key-shortcut/KeyInline.vue';
  import Label from '@/components/html-elements/Label.vue';

  import {
    Systems, System, Formats, Format, Languages, Language, LayoutProps,
  } from '@/config/keyboards/dataTypes';
  import {
    KeyboardLayout,
  } from '@/config/keyboards/keyboardLayout';
  import { layouts as KeyboardLayouts } from '@/config/keyboards/layoutsAll';

  export default defineComponent({
    name: 'LayoutSetup',

    components: {
      Button,
      Keyboard,
      KeyInline,
      Label,
    },

    setup() {
      const { t } = useI18n({ useScope: 'global' });
      const route = useRoute();
      const router = useRouter();
      const store = useStore();

      const activeStep = ref(1);
      const system = ref(Systems);
      const formats = ref(Formats);
      const languages = ref(Object.values(Languages));

      const activeSettings = ref(['', '', '', '']);

      const layoutNames = computed((): {language: Language, names: string[]} => {
        if (activeSettings.value[2] === Languages.RU) {
          if (activeSettings.value[0] === Systems.WIN) {
            return {
              language: Languages.RU,
              names: [
                KeyboardLayouts[Languages.RU].names.Qwerty,
                KeyboardLayouts[Languages.RU].names.Typewriter,
              ],
            };
          }
          return {
            language: Languages.RU,
            names: [
              KeyboardLayouts[Languages.RU].names.RussianApple,
              KeyboardLayouts[Languages.RU].names.RussianApplePC,
            ],
          };
        }

        return {
          language: Languages.EN,
          names: [
            KeyboardLayouts[Languages.EN].names.Qwerty,
            KeyboardLayouts[Languages.EN].names.Dvorak,
            KeyboardLayouts[Languages.EN].names.Colemak,
          ],
        };
      });

      const keyToPress = computed((): 'Ё' | '.' | 'K' => {
        if (activeSettings.value[2] === Languages.RU) {
          if (activeSettings.value[1] === Formats.ISO) {
            return 'Ё';
          }
          if (activeSettings.value[1] === Formats.ANSI) {
            return '.';
          }
        }
        return 'K';
      });

      const selectedLayoutProps = computed((): LayoutProps | null => {
        for (let i = 0; i < activeSettings.value.length; i += 1) {
          if (!activeSettings.value[i]) {
            return null;
          }
        }

        const props: LayoutProps = {
          system: activeSettings.value[0] as System,
          format: activeSettings.value[1] as Format,
          language: activeSettings.value[2] as Language,
          name: activeSettings.value[3] as string,
        };

        return props;
      });

      const definedLayout = computed((): KeyboardLayout | null => {
        const props = selectedLayoutProps.value;
        if (!props) { return null; }

        const layoutsToCheck = KeyboardLayouts[props.language].layouts;
        for (let layoutIx = 0; layoutIx < layoutsToCheck.length; layoutIx += 1) {
          const layout = layoutsToCheck[layoutIx];
          if (layout.isEqual(props)) {
            return layout;
          }
        }

        return null;
      });

      // Define layout by key (if 1-3 settings already selected)

      const defineLayoutByKey = (e: KeyboardEvent): void => {
        for (let i = 0; i < activeSettings.value.length - 1; i += 1) {
          if (activeSettings.value[i] === '') {
            return;
          }
        }

        const activeSystem = activeSettings.value[0] as System;
        const activeFormat = activeSettings.value[1] as Format;
        const activeLanguage = activeSettings.value[2] as Language;

        const definedName = KeyboardLayouts[activeLanguage].defineByKeyPress(e, activeSystem, activeFormat);

        if (definedName) {
          // eslint-disable-next-line no-use-before-define
          selectOption(3, definedName.props.name);
        }
      };

      // Select btn handler

      const setLayout = (): void => {
        const props = selectedLayoutProps.value;
        if (props) { store.commit('layout/set', { settings: props }); }
      };

      const selectOption = (stepIndex: number, value: string): void => {
        if (activeSettings.value[stepIndex] === value) return;

        activeSettings.value[stepIndex] = value;
        activeStep.value = Math.max(activeStep.value, stepIndex + 2);

        if (activeStep.value === 4) {
          document.addEventListener('keydown', defineLayoutByKey);
        }

        if (activeStep.value > 4) {
          document.removeEventListener('keydown', defineLayoutByKey);
          setLayout();
        }
      };

      const rerunSetup = (): void => {
        activeSettings.value = ['', '', '', ''];
        activeStep.value = 1;
      };

      onUnmounted((): void => {
        document.removeEventListener('keydown', defineLayoutByKey);
      });

      return {
        t,
        route,
        router,
        activeStep,
        activeSettings,
        selectOption,
        system,
        formats,
        languages,
        layoutNames,
        keyToPress,
        definedLayout,
        rerunSetup,
      };
    },
  });
</script>

<style lang="sass">
// Animation for a key to press
@keyframes fade-in-out
  0%
    opacity: .3
  50%
    opacity: 1
  100%
    opacity: .3
</style>

<style lang="scss">
// Animation for a key to press
@keyframes fade-in-out {
  0%{
    opacity: .3
  }
  50% {
    opacity: 1
  }
  100% {
    opacity: .3
  }
}
</style>

<style lang="scss" scoped>
.layout-setup {
  padding: 3rem 0;
  display: flex;

  & > * {
    flex: 1;
  }
}

.main-title {
  grid-area: title;
  text-align: center;
  margin-top: 0;
  margin-bottom: 1.25em;
}

// Setup view

.setup-wrapper {
  display: grid;
  grid: "title" min-content
        "settings" auto
        / 100%;
  align-content: center;
}

.settings-wrapper {
  grid-area: settings;
  align-self: center;
  display: flex;
  overflow: visible;
  width: min(100%, 68rem);
  margin: 0 auto;
}

.option-block {
  font-size: 1rem;
  flex: 1;
  background: rgb(var(--color-surface));
  border: 1px solid rgb(var(--color-secondary-border));
  box-shadow: 0px 1px rgb(var(--color-secondary-border));
  border-radius: 1em;
  padding: 1em;
  transition-property: opacity, background;
  transition-duration: .2s;
  transition-timing-function: ease-in;
  position: relative;

  &.disabled {
    opacity: .2;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: none;
    }
  }

  .step-indicator {
    font-size: 1em;
    width: 2em;
    height: 2em;
    line-height: calc(2em - 2px);
    font-weight: 500;
    border-radius: 50%;
    border: 1px solid rgb(var(--color-secondary-border));
    text-align: center;
    color: rgb(var(--color-text-primary));
  }

  .title {
    font-size: 1.5em;
    margin-top: 1em;
    margin-bottom: .25em;
    font-weight: 600;
    text-align: left;
  }

  .description {
    margin-top: 0;
    font-size: 1em;
    margin-bottom: 1.5em;
    color: rgb(var(--color-text-secondary));
  }

  .select-option {
    margin-top: .5em
  }

  .formats-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .format-option {
      svg {
        width: 100%;
        max-width: 100px;

        .enter-border {
          stroke: rgb(var(--color-text-secondary))
        }

        .enter-icon {
          fill: rgb(var(--color-text-secondary))
        }

        .secondary {
          stroke: rgb(var(--color-secondary-border))
        }
      }

      .caption {
        text-align: center;
        margin-top: .8em;
        display: none;
      }
    }

    :deep(.btn) {
      flex: 0 0 48%;
      padding: .625em;

      &.selected {
        svg {
          .enter-border {
            stroke: rgb(var(--color-text-on-primary));
          }

          .enter-icon {
            fill: rgb(var(--color-text-on-primary));
          }

          .secondary {
            stroke: rgb(var(--color-text-on-primary) / 0.3);
          }
        }
      }
    }
  }
  .lang-icon {
    width: 1.25em;
    margin-right: .375em;
  }

  .key-inline {
    font-size: .75em;
  }

  &.selected {
    .step-indicator {
      background: rgb(var(--color-primary));
      border-color: rgb(var(--color-primary));
      color: rgb(var(--color-text-on-primary));
    }
  }

  &.active {
    .key-inline {
      animation: fade-in-out 2.5s ease-in-out both;
      animation-iteration-count: infinite;
    }
  }
}

.option-block + .option-block {
  margin-left: .75rem;
}

// Keyboard view

.layout-preview {
  display: grid;
  grid: "title" min-content
        "subtitle" min-content
        "keyboard" min-content
        "actions" min-content
         / 100%;
  align-content: center;

  .main-title {
    margin-bottom: .375em;
  }

  .subtitle {
    grid-area: subtitle;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  .keyboard-wrapper {
    grid-area: keyboard;
    display: flex;
    justify-content: center;
    margin: 2.125rem auto;
    width: 67%;
  }

  .final-actions {
    grid-area: actions;
    text-align: center;

    .additional-actions {
      margin-top: 1em;
      color: rgb(var(--color-text-secondary));
      margin-bottom: 0;
    }
  }
}

// Transition for switching between Setup and Keyboard view

.soft-switch-enter-from, .soft-switch-leave-to {
  opacity: 0;
}

.soft-switch-enter-active, .soft-switch-leave-active {
  transition: opacity .3s ease-out;
}
</style>
